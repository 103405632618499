import ItemService from "./ItemService";
import cyrillicToTranslit from "cyrillic-to-translit-js";
import {Endpoints} from "../config.js";
import {capitalizeName} from "../utils/capitalize";
import {forEach, sortBy} from "lodash";
import {COMMUNITY_BY_COORDS} from "../utils/DataFetcher";

export class SpecialInitiativeService extends ItemService {

  constructor(onUpdateState, lang) {
    super(Endpoints.SpecialInitiatives.path, onUpdateState, lang);
  }


  GetInitialState(userRoles) {
    let state = super.GetInitialState(userRoles);

    state = {
      location: [50.4501, 30.5234],
      zoom: 12,
      ...state,
    };
    return state;
  }

  async Action(e, state) {
    // base class is not called and contains only default implementation
    // could be improved ...

    console.log("FieldChanged", e);

    switch (e.fieldName) {
      case "newCommunityLocation":
        state = await this.SetNewCommunityLocation(state, e.value);
        break;
      case "addCommunity":
        state = await this.AddCommunity(state, e.value);
        break;
      case "removeCommunity":
        state = await this.RemoveCommunity(state, e.value);
        break;
      default:
        // never modify state, always clone
        state = { ...state };
        state[e.fieldName] = e.value;
        break;
    }

    state = this.SetStateChanged(state);

    return state;
  }

  UpdateStateFromItem(state, item) {
    state = super.UpdateStateFromItem(state, item);

    state = {
      ...state,
      // showCellPhone:
      //     item.cellPhone && item.cellPhone !== this._dummyPhoneNumber,
      // name: item.name ? capitalizeName(item.name) : '',
      // nameTranscript: item.nameTranscription ? capitalizeName(item.nameTranscription) : '',
      // events: item.shortEvents
      //     ? sortBy(item.shortEvents, [(ev) => -ev.id])
      //     : [],
    };

    return state;
  }

  ValidateItem(state) {
    let result = super.ValidateItem(state);
    //
    // this.validationRequired(result, state, "name");
    // this.validationRequired(result, state, "nameTranscription");
    // this.validationRequired(result, state, "email");
    // this.validationSelectionRequired(result, state, "gender");
    // //this.validationRequired(result, state, "cellPhone");
    // this.validationSelectionRequired(result, state, "employerTypeId");
    // this.validationSelectionRequired(result, state, "regionId");
    // this.validationRequired(result, state, "employerName");
    // this.validationRequired(result, state, "employerAddress");
    // if (state.showCellPhone) {
    //   if (
    //       state.cellPhone === undefined ||
    //       state.cellPhone === "" ||
    //       state.cellPhone.indexOf("_") > -1
    //   ) {
    //     result.cellPhone = "Required";
    //   }
    // }

    return result;
  }

  MapStateToModel(state) {
    let data = super.MapStateToModelMultiPart(state);

    let params = this.GetApiPutParams();

    for (let p of params) {
      // if (p.name === "name_transcription" && state.nameTranscription) {
      //   data.append(p.name, state.nameTranscription);
      // }
      //
          //else if (p.name === "gender") {
          //   if (state.gender?.value) {
          //     data.append(p.name, state.gender.value);
      //   }
     // else {
        this.MapProperty(data, state, p);
     // }
    }

    // state.documents.forEach((document, index) => {
    //   data.append(`document_${index}`, null);
    // });


    return data;
  }

  GetApiPutParams() {
    return [
      {
        in: "path",
        name: "id",
        type: "integer",
        format: "int32",
        required: true,
      },
      {
        in: "formData",
        name: "nameEng",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "nameUa",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "descriptionEng",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "descriptionUa",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "startDate",
        type: "string",
        format: "date",
        required: false,
      },
      {
        in: "formData",
        name: "endDate",
        type: "string",
        format: "date",
        required: false,
      },
      {
        in: "formData",
        name: "partners",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "partnersName",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "owner",
        type: "string",
        required: false,
      },
      // Attachments
      {
        in: "formData",
        name: "photo",
        type: "file",
        required: false,
      },
      {
        in: "formData",
        name: "report",
        type: "file",
        required: false,
      },
    ];
  }

  async SetNewCommunityLocation(state, value) {

    let result;
    if (value?.latLng?.length === 2) {
      // lat lon twisted in api: lon lat
      const location = `${value.latLng[1]},${value.latLng[0]}`;
      result = await this.Fetch("POST", `${this.url}${COMMUNITY_BY_COORDS}`, {coordinates: location});
    }

    if(!result.community)
    {
      result = {community: {name_en: "No municipality found", name_ua: "уніципалітет не знайдено"}};
    }

    const newState = {...state, newCommunity: result.community, newCommunityName: this._lang === "Eng" ? result.community.name_en : result.community.name_ua};

    return newState;
  }

  async AddCommunity(state, value) {
    let url = `${this.url}${Endpoints.SpecialInitiatives.path}/${state.id}/community`;
    await this.Fetch("post", url, {community_id:value.id});

    const newState = {...state, shortCommunities: [...state.shortCommunities, {nameEn: value.name_en,nameUa: value.name_ua}], newCommunity: null, newCommunityName: null};

    return newState;
  }

  async RemoveCommunity(state, value) {
    let url = `${this.url}${Endpoints.SpecialInitiatives.path}/${state.id}/community/${value.id}`;
    await this.Fetch("delete", url);

    const newState = {
      ...state,
      shortCommunities: state.shortCommunities.filter(community => community.id !== value.id)
    };

    return newState;
  }
}

export default SpecialInitiativeService;
